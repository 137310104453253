.catalogSliderWrapper {
    margin-top: 40px;
    position: relative;

    padding: 0 80px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    padding-bottom: 64px;

    gap: 100px;

    @media (max-width: 1200px) {
        gap: 40px;

        padding: 0px 34px;
        padding-bottom: 64px;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 0px;
        padding: 0px 16px;
    }

    .sliderMain {
        margin-bottom: 10px;

        :global {
            .slick-prev {
                left: 10px !important;
                z-index: 10;
            }

            .slick-next {
                right: 10px !important;
                z-index: 10;
            }
        }
    }

    .sliderSecond {
        :global {
            .slick-current {
                img {
                    display: block;
                    // outline: 2px solid red;
                    -webkit-box-shadow: 0px 0px 0px 2px rgba(228, 31, 28, 1) inset;
                    -moz-box-shadow: 0px 0px 0px 2px rgba(228, 31, 28, 1) inset;
                    box-shadow: 0px 0px 0px 2px rgba(228, 31, 28, 1) inset;
                }
            }
        }
    }

    .miniaturesWrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        gap: 20px;

        .secondImage {
            width: 100% !important;
        }
    }

    .mainImage {
        width: 100%;

        clip-path: polygon(0% 0%, 100% 0, 100% 100%, 8% 100%, 0 82%);

        aspect-ratio: 1/0.59;
        object-fit: cover;
        object-position: center;
    }

    .secondImage {
        clip-path: polygon(0% 0%, 100% 0, 100% 100%, 8% 100%, 0 82%);
        width: 88% !important;
        border: 2px solid transparent;
        transition: all 150ms ease;

        aspect-ratio: 1/0.6;
        object-fit: cover;
        object-position: center;
        cursor: pointer;

        &:hover {
            border: 2px solid #E41F1C;
        }
    }

    .activeSecondImage {
        border: 2px solid #E41F1C;
    }

    .miniatureImagesWrapper {
        margin-top: 30px;
        display: grid;

        grid-template-columns: repeat(3, 1fr);

        gap: 32px;

        img {
            width: 100%;
        }
    }

    .sliderImages {
        max-width: 100%;
    }

    .sliderTitle {
        background: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(10px);

        width: 100%;

        padding: 8px 0;
        padding-left: 8px;

        margin-bottom: 8px;

        .title {
            color: #a3a3a3;
            font-size: 40px;
            line-height: 1;
            font-weight: 500;

            margin-bottom: 2px;

            @media (max-width: 1200px) {
                font-size: 20px;
            }

            @media (max-width: 768px) {
                font-size: 14px;
            }
        }

        .subtitle {
            color: #fff;
            font-size: 54px;
            line-height: 1;
            font-weight: 500;

            @media (max-width: 1200px) {
                font-size: 32px;
            }

            @media (max-width: 768px) {
                font-size: 24px;
            }
        }
    }

    .stats {
        padding: 20px;

        background-color: rgba(0, 0, 0, 0.36);
        backdrop-filter: blur(20px);

        clip-path: polygon(0% 0%, 100% 0, 100% 100%, 8% 100%, 0 82%);

        @media (max-width: 768px) {
            padding: 12px;
        }

        .statsItem {
            background: rgba(0, 0, 0, 0.2);

            display: flex;
            //   align-items: center;
            justify-content: space-between;

            padding: 12px 16px;
            margin-bottom: 4px;

            @media (max-width: 768px) {
                padding: 4px 8px;
            }

            .name {
                color: #a3a3a3;
                font-size: 16px;
                font-weight: 300;

                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }

            .value {
                color: #fff;
                font-size: 16px;
                font-weight: 400;

                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }

            .statsWrap {
                display: flex;
                align-items: center;
            }

            .diagrams {
                width: 160px;
                height: 24px;
                background: rgba(255, 255, 255, 0.4);

                margin-right: 12px;

                .activeDiagrams {
                    transition: width 0.3s;
                    width: 0;
                    height: 24px;
                    background: rgba(255, 255, 255, 0.6);
                }
            }

            .priceBlock {
                display: flex;
                flex-direction: column;

                .priceValue {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 400;
                    text-align: right;

                    &.hasExtraPrice {
                        color: #7f7f7f;
                        font-size: 20px;
                        font-weight: 500;

                        text-decoration: line-through;
                        text-decoration-color: #e41f1c;
                    }
                }

                .extraPrice {
                    color: #e41f1c;
                    font-size: 24px;
                    font-weight: 500;
                    text-align: right;
                }
            }
        }
    }

    .makeOrderButton {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #404040;

        padding: 12px 36px;

        margin-top: 20px;

        @media (max-width: 768px) {
            padding: 8px 20px;
        }

        span {
            color: #fff;
            font-size: 20px;
            font-weight: 500;

            @media (max-width: 768px) {
                font-size: 16px;
            }
        }
    }

    .getConsultant {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-top: 27px;

        span {
            color: #ccc;
            font-size: 20px;
            font-weight: 500;
        }
    }
}