%baseModal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;

    padding-top: 80px;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
}

.modal {
    @extend %baseModal;
    display: none;
}

.modalActive {
    @extend %baseModal;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContentWrapper {
    position: relative;
    width: 840px;
    padding: 60px 100px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100px 100%, 0 70%);
    background: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(40px);

    @media (max-width: 1024px) {

        padding: 50px 80px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 80px 100%, 0 75%);

    }

    @media (max-width: 767px) {

        padding: 50px 40px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 40px 100%, 0 80%);

    }
}

.closeButton {
    position: absolute;
    top: 40px;
    right: 40px;

    width: 24px;
    height: 24px;
    cursor: pointer;

    rect {
        transition: all 200ms ease;
    }

    &:hover {
        rect {
            fill: #E41F1C;
        }
    }

    @media (max-width: 1024px) {
        top: 35px;
        right: 35px;
    }

    @media (max-width: 767px) {
        top: 20px;
        right: 15px;
    }
}