.main {
    width: 100%;

    h2 {
        color: #FFF;
        font-size: 24px;
        font-weight: 500;
        line-height: 90%;

        @media (min-width: 768px) {
            font-size: 32px;
        }

        @media (min-width: 1025px) {
            font-size: 40px;
        }
    }
}

.sectionReview {
    width: 100%;
    background: #000;
}

.topBlockReview {
    width: 100%;
    padding: 25px 16px 40px;

    @media (min-width: 768px) {
        padding: 30px 34px 50px;
    }

    @media (min-width: 1100px) {
        padding: 40px 80px;
    }
}

.headReview {
    display: flex;
}

.textReview {
    margin-top: 12px;

    color: #A3A3A3;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;

    @media (min-width: 768px) {
        margin-top: 16px;

        line-height: normal;
    }

    @media (min-width: 1025px) {
        margin-top: 20px;

        font-size: 20px;
        line-height: 28px;
    }
}

.interactiveBlock {
    padding-top: 40px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    button {
        align-self: center;
    }

    &>div {
        display: flex;
        flex-direction: column;
        gap: 8px;

        p {
            color: #CCC;
            font-size: 16px;
        }
    }

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;

        &>div {
            align-self: flex-end;

            flex-direction: row;
            gap: 20px;
        }
    }

    @media (min-width: 1025px) {
        padding-top: 50px;

        &>div {
            gap: 30px;

            p {
                font-size: 20px;
            }
        }
    }
}

.sliderWrapper {
    width: 100%;
}

.slider {
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 5%;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.00) 100%);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;

        width: 5%;
        height: 100%;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.00) 100%);
    }
}

.slideContainer {
    position: relative;

    width: 100%;
    height: 30vw;
}

%baseImg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transition: all 700ms ease;
}

.slideImg {
    @extend %baseImg;
}

.leftImg {
    @extend %baseImg;
    width: 120%;
    clip-path: polygon(0% 0%, 100% 0%, 80% 100%, 0% 100%);
}

.rightImg {
    @extend %baseImg;
    clip-path: polygon(24% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.positionPrev {
    z-index: 3;
    left: 16px;

    @media (min-width: 768px) {
        left: 20px;
    }

    @media (min-width: 1025px) {
        left: 40px;
    }
}

.positionNext {
    z-index: 3;
    right: 16px;

    @media (min-width: 768px) {
        right: 20px;
    }

    @media (min-width: 1025px) {
        right: 40px;
    }
}

.sectionApplication {
    width: 100%;
    padding: 50px 0 0;
    border-bottom: 1px solid #E41F1C;
    background: #000;

    @media (min-width: 768px) {
        padding: 70px 0 0;
    }

    @media (min-width: 1025px) {
        padding: 100px 0 0;
    }
}

.appWrapper {
    position: relative;
    z-index: 1;
    max-width: 460px;
    margin: 0 auto;
    padding: 0 16px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    @media (min-width: 768px) {
        max-width: 540px;
    }

    @media (min-width: 1025px) {
        max-width: 740px;
        gap: 40px;
    }
}

.botFon {
    position: relative;
    width: 100%;
    margin-top: -30px;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 50%;
        background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    }

    img {
        width: 100%;
        height: 100%;
        margin-bottom: -5px;
    }

    @media (min-width: 768px) {
        margin-top: -50px;
    }

    @media (min-width: 1025px) {
        margin-top: -70px;
    }
}