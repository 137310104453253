.container {
    width: 100%;
    padding: 60px 0 0;
    background: #000;
}

.fon {
    position: relative;
    width: 100%;
    padding: 40px 80px 120px;
    background: url(../../../assets/img/workPoint.png);
    background-size: 100% 100%;
    overflow: hidden;
}

.wrapper {
    width: 60%;

    display: flex;
    flex-direction: column;
    gap: 40px;
}

.block {
    position: relative;
    z-index: 2;
    width: 100%;
    min-height: 250px;
    padding: 20px 120px 20px 100px;
    background: #BDBDBD;

    transform: skewX(-26.56deg) translateX(100%);
    transform-origin: 50% 100% 0;

    transition: all 800ms ease;

    display: flex;
    align-items: center;
    gap: 16px;

    &>p {
        transform: skewX(26.56deg);

        text-align: right;
        font-size: 160px;
        font-weight: 700;
        text-transform: uppercase;
    }

    div {
        transform: skewX(26.56deg);
        display: flex;
        flex-direction: column;
        gap: 20px;

        p {
            color: #FFF;
            font-size: 32px;
            font-weight: 500;
            line-height: 100%;

            &:last-child {
                color: #000;
                font-size: 20px;
                font-weight: 300;
                line-height: 28px;
            }
        }
    }
}

.anim {
    transform: skewX(-26.56deg) translateX(0);
}

.point2 {
    margin-left: 6.25%;
}

.point3 {
    margin-left: 12.5%;
}

.point4 {
    margin-left: 18.75%;
}

.point5 {
    margin-left: 25%;
}



@media (max-width: 1024px) {

    .container {
        padding: 40px 0 0;
    }

    .fon {
        padding: 20px 34px 80px;
    }

    .wrapper {
        width: 90%;
        gap: 28px;
    }

    .block {
        min-height: 170px;
        padding: 20px 70px 20px 50px;
        gap: 20px;

        &>p {
            font-size: 96px;
        }

        div {
            gap: 12px;

            p {
                font-size: 24px;

                &:last-child {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }
    }

    .point2 {
        margin-left: 0px;
    }

    .point3 {
        margin-left: 0px;
    }

    .point4 {
        margin-left: 0px;
    }

    .point5 {
        margin-left: 0px;
    }

}



@media (max-width: 767px) {

    .container {
        padding: 30px 0 0;
    }

    .fon {
        padding: 20px 0 56px 0;
    }

    .wrapper {
        width: 110%;
        gap: 20px;
    }

    .block {
        min-height: 220px;
        padding: 20px 75px 20px 40px;
        gap: 8px;

        div {
            p {
                font-size: 20px;
            }
        }
    }

    .point1 {
        margin-left: -70px;
    }

    .point2 {
        margin-left: -70px;
    }

    .point3 {
        margin-left: -70px;
    }

    .point4 {
        margin-left: -70px;
    }

    .point5 {
        margin-left: -70px;
    }

}