.container {
    width: 100%;
    height: 80px;
    padding: 0 40px;
    border-bottom: 1px solid #E41F1C;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(40px);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 104px;
    height: 40px;
    flex-shrink: 0;
    -webkit-tap-highlight-color: transparent;
}



.menuButton {
    width: 40px;
    height: 22px;
    background: url(../../assets/icons/mobile_menu.svg);
    background-size: cover;
    -webkit-tap-highlight-color: transparent;
}

.menuButtonClose {
    width: 22px;
    height: 22px;
    background: url(../../assets/icons/mobile_menu_close.svg);
    background-size: cover;
    -webkit-tap-highlight-color: transparent;
}

%mobileMenuBase {
    position: fixed;
    top: 80px;
    right: 0;
    bottom: 0;
    z-index: 10;

    padding: 40px 50px;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(40px);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #808080 transparent;

    transition: all 500ms ease-out;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #808080;
    }
}

.mobileMenu {
    @extend %mobileMenuBase;
    visibility: hidden;
    transform: translateX(100%);
}

.mobileMenuOpen {
    @extend %mobileMenuBase;
    visibility: visible;
    transform: translateX(0);
}



@media (max-width: 767px) {

    .container {
        height: 48px;
        padding: 0 16px;
    }

    .logo {
        width: 72px;
        height: 28px;
    }

    .menuButton {
        width: 29px;
        height: 16px;
    }

    .menuButtonClose {
        width: 16px;
        height: 16px;
    }

    %mobileMenuBase {
        top: 48px;
        padding: 20px 50px;
    }

}