.container {
    width: 100%;
    background: #000;
    padding: 50px 16px 0;

    &>div {
        width: 100%;
        margin-top: 20px;

        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    @media (min-width: 768px) {
        padding: 70px 34px 0;

        &>div {
            gap: 40px;
        }
    }

    @media (min-width: 1100px) {
        padding: 100px 80px 0;

        &>div {
            margin-top: 30px;

            flex-direction: row;
            align-items: center;
            gap: 90px;
        }
    }
}

.topSpec {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 8px;

    div {
        width: 100%;
        height: 24px;
        padding: 0 8px;
        background: rgba(255, 255, 255, 0.30);
        backdrop-filter: blur(10px);

        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            color: rgba(255, 255, 255, 0.60);
            font-size: 14px;
            font-weight: 300;
        }

        p {
            color: #FFF;
            font-size: 16px;
        }
    }

    @media (min-width: 768px) {
        gap: 10px;

        div {
            height: 32px;
            padding: 0 20px;

            span {
                font-size: 16px;
            }

            p {
                font-size: 20px;
            }
        }
    }
}

.botSpec {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;

    &>div {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 4px;

        svg {
            flex-shrink: 0;
        }

        &>span {
            width: 83px;

            color: #7F7F7F;
            font-size: 14px;
            line-height: 100%;
        }

        p {
            color: #FFF;
            font-size: 14px;

            span {
                color: #FFF;
                font-size: 20px;
            }
        }
    }

    @media (min-width: 768px) {
        gap: 28px;

        &>div {
            gap: 8px;

            svg {
                width: 48px;
                height: 48px;
            }

            &>span {
                width: 96px;

                font-size: 16px;
            }

            p {
                font-size: 16px;

                span {
                    font-size: 32px;
                }
            }
        }
    }
}

.figureSpec {
    flex-grow: 1;
    height: 24px;
    background: rgba(255, 255, 255, 0.30);
    clip-path: polygon(12px 0%, 100% 0%, calc(100% - 12px) 100%, 0% 100%);

    div {
        height: 100%;
        background: rgba(255, 255, 255, 0.60);
        clip-path: inherit;
    }

    @media (min-width: 768px) {
        height: 32px;
        clip-path: polygon(16px 0%, 100% 0%, calc(100% - 16px) 100%, 0% 100%);
    }
}