.container {
    width: 100%;
    padding: 80px 0 30px 0;
}

.form {
    width: 100%;
    padding: 70px 80px 0 80px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px 30px;
    align-items: center;
}

%baseFormBlock {
    display: flex;
    align-items: center;
    gap: 32px;

    p {
        flex-shrink: 0;

        color: #FFF;
        font-size: 24px;
    }
}

.formBlock {
    @extend %baseFormBlock;
}

.formBudget {
    @extend %baseFormBlock;
    grid-column: 1/3;
}

.formMessendger {
    @extend %baseFormBlock;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;

    div {
        display: flex;
        gap: 20px;

        div {
            position: relative;
            width: 40px;
            height: 40px;

            &:active {
                transform: scale(0.9);
            }

            input {
                position: relative;
                z-index: 1;
                opacity: 0;

                width: 40px;
                height: 40px;
                cursor: pointer;
            }

            %label {
                position: absolute;
                top: 0;
                left: 0;

                width: 40px;
                height: 40px;
            }

            input+.whatsapp {
                @extend %label;
            }

            input:checked+.whatsapp {
                @extend %label;

                path {
                    fill: #43D854;
                }
            }

            input+.telegram {
                @extend %label;
            }

            input:checked+.telegram {
                @extend %label;

                path {
                    fill: #08C;
                }
            }
        }
    }
}

.politic {
    grid-column: 1/3;
    max-width: 314px;

    color: #D9D9D9;
    font-size: 14px;

    a {
        color: #D9D9D9;
        font-size: 14px;
        text-decoration-line: underline !important;
    }
}

.buttonWrapper {
    justify-self: end;
}

.errorText {
    margin-top: 10px;
    padding: 0 80px;
    color: #E41F1C;
    font-size: 32px;
    text-align: right;
}

@media (max-width: 1024px) {

    .container {
        padding: 60px 0 20px 0;
    }

    .form {
        padding: 40px 34px 0 34px;

        grid-template-columns: 1fr 1fr 1fr;
        gap: 50px 20px;
    }

    %baseFormBlock {
        gap: 20px;

        p {
            font-size: 16px;
        }
    }

    .formMessendger {
        gap: 10px;

        div {
            div {
                width: 35px;
                height: 35px;

                input {
                    width: 35px;
                    height: 35px;
                }

                %label {
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }

    .politic {
        max-width: 270px;
        font-size: 12px;

        a {
            font-size: 12px;
        }
    }

    .errorText {
        margin-top: 10px;
        padding: 0 34px;
        font-size: 24px;
    }

}

@media (max-width: 767px) {

    .container {
        padding: 30px 0 15px 0;
    }

    .form {
        padding: 30px 16px 0 16px;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    %baseFormBlock {
        gap: 8px;

        p {
            font-size: 16px;
        }
    }

    .formBudget {
        grid-row: 3/4;
        grid-column: 1/2;
    }

    .formMessendger {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        div {
            gap: 20px;

            div {
                width: 28px;
                height: 28px;

                input {
                    width: 28px;
                    height: 28px;
                }

                %label {
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }

    .politic {
        grid-row: 10/11;
        grid-column: 1/2;
        justify-self: center;
        margin-top: -8px;
    }

    .buttonWrapper {
        margin-top: 5px;
        justify-self: center;
    }

    .errorText {
        margin-top: 0px;
        padding: 0 16px;
        font-size: 18px;
        text-align: center;
    }

}