.container {
    width: 100%;
    padding: 25px 0;
    background: #000;

    @media (min-width: 768px) {
        padding: 30px 0;
    }

    @media (min-width: 1025px) {
        padding: 40px 0;
    }
}

.wrapper {
    margin-top: 20px;
    padding: 0 16px;

    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;

    @media (min-width: 768px) {
        padding: 0 34px;
        grid-template-columns: 1fr 1fr;
        gap: 25px;
    }

    @media (min-width: 1025px) {
        margin-top: 40px;
        padding: 0 80px;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 35px;
    }
}

.block {
    width: 100%;
    opacity: 0;
    transform: scale(0.7);
    transition: all 900ms ease;

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.anim {
    opacity: 1;
    transform: scale(1);
}

.headBlock {
    width: 100%;
    height: 40px;
    background: rgba(255, 255, 255, 0.70);
    backdrop-filter: blur(40px);
    flex-shrink: 0;

    display: flex;
    align-items: center;

    p {
        color: #404040;
        font-size: 16px;
        font-weight: 500;
        line-height: 90%;
    }

    @media (min-width: 768px) {
        height: 60px;

        p {
            font-size: 20px;
        }
    }

    @media (min-width: 1150px) {
        height: 80px;
        padding-right: 5px;

        p {
            font-size: 32px;
        }
    }
}

.icon {
    width: 96px;
    height: 100%;
    padding-left: 26px;
    background: #E41F1C;
    clip-path: polygon(0% 0%, 76px 0%, 100% 100%, 0% 100%);
    flex-shrink: 0;

    display: flex;
    align-items: center;

    @media (min-width: 768px) {
        padding-left: 21px;
        clip-path: polygon(0% 0%, 66px 0%, 100% 100%, 0% 100%);
    }

    @media (min-width: 1025px) {
        padding-left: 16px;
        clip-path: polygon(0% 0%, 56px 0%, 100% 100%, 0% 100%);

        svg {
            width: 48px;
            height: 48px;
        }
    }
}

.textBlock {
    width: 100%;
    height: 100%;
    padding: 20px 20px 40px;
    background-image: url(../../../assets/img/advantages_fon.png);
    background-size: cover;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 20px 100%, 0% calc(100% - 40px));

    color: #FFF;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;

    @media (min-width: 1025px) {
        font-size: 20px;
        line-height: 28px;
    }
}

.four {
    @media (min-width: 1025px) {
        transform: translateX(calc(50% + 22px)) scale(0.7);
    }
}

.four.anim {
    @media (min-width: 1025px) {
        transform: translateX(calc(50% + 22px)) scale(1);
    }
}

.five {
    @media (min-width: 768px) {
        transform: translateX(calc(50% + 12px)) scale(0.7);
    }

    @media (min-width: 1025px) {
        transform: translateX(calc(50% + 22px)) scale(0.7);
    }
}

.five.anim {
    @media (min-width: 768px) {
        transform: translateX(calc(50% + 12px)) scale(1);
    }

    @media (min-width: 1025px) {
        transform: translateX(calc(50% + 22px)) scale(1);
    }
}