.navigatoin {
    display: flex;
    gap: 32px;
    transition: all 300ms linear;
}

%baseLink {
    color: #999;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    transition: all 200ms ease-in;

    &:hover {
        transform: translateY(-4px);
        color: #fff;
    }

    &:active {
        transform: translateY(-4px);
        transform: scale(0.9);
    }
}

.link {
    @extend %baseLink;
}

.activeLink {
    @extend %baseLink;
    color: #E41F1C;

    &:hover {
        transform: translateY(0px);
        color: #E41F1C;
    }
}



@media (max-width: 1250px) {

    .navigatoin {
        gap: 15px;
    }

}



@media (max-width: 1150px) {

    .navigatoin {
        flex-direction: column;
    }

    %baseLink {
        -webkit-tap-highlight-color: transparent;

        &:active {
            color: #fff;
        }
    }

}



@media (max-width: 767px) {

    %baseLink {
        font-size: 18px;
        line-height: normal;
    }

}