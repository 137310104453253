.sectionTechnologies {
    width: 100%;
    background: #000;
}

.padding {
    padding: 50px 16px 0;

    @media (min-width: 768px) {
        padding: 60px 34px 0;
    }

    @media (min-width: 1100px) {
        padding: 100px 80px 0;
    }
}

.sliderTechWrapper {
    width: 100%;
    margin-top: 12px;

    @media (min-width: 768px) {
        margin-top: 16px;
    }

    @media (min-width: 1025px) {
        margin-top: 30px;
    }
}

.slideTech {
    width: 100%;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 20px 100%, 0 calc(100% - 40px));

    display: flex;
    flex-direction: column;

    img {
        width: 100%;
    }

    button {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;

        width: 150px;
        height: 40px;
        padding-right: 20px;

        color: #FFF;
        font-size: 14px;
        line-height: 120%;
        text-align: right;
        text-decoration-line: underline;
    }
}

%reviewsBase {
    position: relative;
    width: 100%;
    background-color: #D9D9D9;
    transition: all 350ms ease;

    display: flex;
    flex-direction: column;
    gap: 4px;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 60%;
        background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(217, 217, 217, 0) 50%, rgba(217, 217, 217, 1) 77%, rgba(217, 217, 217, 1) 100%);
        transition: all 350ms ease;
    }

    span {
        color: #FFF;
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
    }

    div {
        display: grid;
        transition: all 350ms ease;

        p {
            min-height: 0px;

            font-size: 16px;
            font-weight: 300;
            line-height: 22px;
        }
    }

    @media (min-width: 768px) {
        gap: 8px;
    }

    @media (min-width: 1250px) {
        gap: 16px;

        span {
            font-size: 28px;
            line-height: 30px;
        }

        div {
            p {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
}

.reviewsHide {
    @extend %reviewsBase;
    padding: 12px 20px 140px 20px;

    &::after {
        opacity: 1;
    }

    div {
        grid-template-rows: 0fr;
    }
}

.reviews {
    @extend %reviewsBase;
    padding: 12px 20px 40px 20px;

    &::after {
        opacity: 0;
    }

    div {
        grid-template-rows: 1fr;
    }
}

.positionPrev {
    height: 200px;
    left: 0px;
}

.positionNext {
    height: 200px;
    right: 0px;
}

.techFon {
    position: relative;
    margin-top: -15.2vw;
    width: 100%;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 40%;
        background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 7%;
        background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    }

    img {
        width: 100%;
    }
}