.container {
    position: relative;
    z-index: 1;

    width: 100%;
    background: transparent;
    background-image: url(../../../assets/img/new_shadow.png);
    background-size: 100% auto;
    background-repeat: no-repeat;

    overflow-x: hidden;
}

.topBlockContainer {
    position: relative;

    width: 100%;

    .topBlockBackgroundImg {
        width: 100%;
        margin-bottom: -5px;
    }

    .topBlock {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        background: transparent;
        background-image: url(../../../assets/img/test_red_strip.svg);
        background-size: 100% auto;
        background-repeat: no-repeat;

        &::after {
            content: '';

            position: absolute;
            right: -6.25%;
            bottom: -10%;

            width: 60%;
            height: 20%;
            border-radius: 100%;
            opacity: 0.8;
            background: #000;
            filter: blur(20px);
        }

        .mainCarName {
            position: absolute;
            top: 24%;
            right: 6.25%;

            // animation: carNameAnim 400ms ease-out;

            p {
                color: #FFF;
                font-size: 4vw;
                font-weight: 500;
                line-height: 100%;
                text-transform: uppercase;

                &:last-child {
                    font-size: 8vw;
                }
            }
        }

        // @keyframes carNameAnim {
        //     0% {
        //         opacity: 0;
        //     }

        //     100% {
        //         opacity: 1;
        //     }
        // }

        .topBlockCar {
            position: absolute;
            right: -6.25%;
            bottom: -13%;
            z-index: 1;

            width: 67.2%;
            height: 66%;
            object-fit: contain;
            transition: all 800ms ease;

            // animation: carAnim 400ms ease-out;
        }

        .commonCar {
            transform: translateX(-17%);
        }

        // @keyframes carAnim {
        //     0% {
        //         transform: translateX(100%);
        //     }

        //     100% {
        //         transform: translateX(0);
        //     }
        // }

        .rightGradient {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;

            width: 25%;
            height: 100%;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);

            @media (max-width: 767px) {
                height: 98%;
            }
        }
    }
}

.sliderAkcii {
    width: 100%;
    height: 100%;

    @media (max-width: 767px) {
        overflow: visible !important;
    }
}

.slideInTopBlock {
    position: relative;
    width: 100%;
    height: 100%;
}

// .buttonsAkcii {
//     position: absolute;
//     top: 30%;
//     left: 40px;

//     display: flex;
//     flex-direction: column;
//     gap: 20px;
// }

.buttonPositionPrev {
    bottom: 20px !important;
    left: 25% !important;

    @media (max-width: 1300px) {
        bottom: 3px !important;
    }

    @media (max-width: 1024px) {
        left: 15% !important;
    }

    @media (max-width: 767px) {
        bottom: 60px !important;
        left: 5% !important;
    }
}

.buttonPositionNext {
    bottom: 20px !important;
    left: 35% !important;

    @media (max-width: 1300px) {
        bottom: 3px !important;
    }

    @media (max-width: 1024px) {
        left: 25% !important;
    }

    @media (max-width: 767px) {
        left: 5% !important;
    }
}

.headerContainer {
    margin-top: 6.25%;

    display: flex;
    align-items: center;
    gap: 12px;

    div {
        width: 66px;
        height: 23px;
        flex-shrink: 0;
        background: #656565;
    }

    h2 {
        color: #656565;
    }
}

.slider {
    width: 100%;
    margin-top: 6%;

    display: flex;
    justify-content: center;
}

.sliderWrapper {
    width: 100%;
    padding: 0 6.25%;
}

.sliderTest {
    padding: 3.13vw 0 0 0 !important;
}

%baseSlide {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    .slideImg {
        position: relative;

        width: 75%;
        height: 11.72vw;
        transition: all 350ms ease-in;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .slideName {
        margin-top: 5.6vw;

        visibility: hidden;
        opacity: 0;
        transition: all 350ms ease-in;

        color: #FFF;
        text-align: center;
        font-size: 32px;
    }

    .slidePrice {
        margin-top: 8px;

        visibility: hidden;
        opacity: 0;
        transition: all 350ms ease-in;

        color: #A3A3A3;
        text-align: center;
        font-size: 24px;
    }
}

.slide {
    @extend %baseSlide;
}

.slideActive {
    @extend %baseSlide;

    .slideImg {
        transform: scale(1.5);
    }

    .slideName {
        visibility: visible;
        opacity: 1;
        transform: translateX(-45px);
    }

    .slidePrice {
        visibility: visible;
        opacity: 1;
        transform: translateX(-45px);
    }
}

.buttonContainer {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 14.85vw;
    pointer-events: none;
}

// Слайдер конец


.orderButtonContainer {
    width: 100%;
    margin-top: 32px;

    display: flex;
    justify-content: center;

    button {
        transform: translateX(-45px);
    }
}



@media (max-width: 1024px) {

    .container {
        background-image: url(../../../assets/img/red_strip_tablet.svg);
    }

    .buttonChangeContainer {
        left: 34px;
    }

    .topBlockContainer {
        .topBlock {
            background-image: url(../../../assets/img/red_strip_tablet.svg);

            &::after {
                right: -12.24%;
                bottom: -7%;

                width: 70%;
                height: 17%;
            }

            .mainCarName {
                top: 37%;
                right: 4.5%;

                p {
                    font-size: 4vw;

                    &:last-child {
                        font-size: 7vw;
                    }
                }
            }

            .topBlockCar {
                right: -12.24%;
                bottom: -10%;

                width: 72.65%;
                height: 57.6%;
            }
        }
    }

    .headerContainer {
        margin-top: 10.42%;
        gap: 8px;

        div {
            width: 26px;
        }
    }

    .slider {
        margin-top: 5.5%;
    }

    .sliderWrapper {
        padding: 0 4.42%;
    }

    .sliderTest {
        padding: 4.88vw 0 0 0 !important;
    }

    %baseSlide {
        .slideImg {
            width: 70%;
            height: 19.53vw;
        }

        .slideName {
            margin-top: 6.64vw;

            font-size: 20px;
        }

        .slidePrice {
            margin-top: 4px;
            font-size: 16px;
        }
    }

    .slideActive {
        .slideName {
            transform: translateX(-25px);
        }

        .slidePrice {
            transform: translateX(-25px);
        }
    }

    .buttonContainer {
        height: 24.41vw;
    }

    // Слайдер конец

    .orderButtonContainer {
        margin-top: 20px;
        padding: 0 4.42%;

        justify-content: flex-start;

        button {
            transform: translateX(0);
        }
    }

}



@media (max-width: 767px) {

    .container {
        background-image: url(../../../assets/img/red_strip_mobile.svg);
    }

    .buttonChangeContainer {
        top: 45%;
        left: 16px;
    }

    .topBlockContainer {
        .topBlock {
            background-image: none;

            &::after {
                right: -16%;

                width: 90%;
            }

            .mainCarName {
                top: 33%;

                p {
                    font-size: 7vw;

                    &:last-child {
                        font-size: 12.5vw;
                    }
                }
            }

            .topBlockCar {
                right: -16%;

                width: 97.8%;
                height: 55.7%;
            }
        }
    }

    .headerContainer {
        margin-top: 62%;
        gap: 4px;

        div {
            width: 12px;
            height: 14px;
            background: #BDBDBD;
        }

        h2 {
            color: #BDBDBD;
        }
    }

    .slider {
        margin-top: 9.5%;
    }

    .sliderWrapper {
        padding: 0 4.44%;
    }

    .sliderTest {
        padding: 4.44vw 0 0 0 !important;
    }

    %baseSlide {
        .slideImg {
            width: 70%;
            height: 18vw;
        }

        .slideName {
            margin-top: 9.7vw;
        }

        .slidePrice {
            margin-top: 2px;
        }
    }

    .slideActive {
        .slideName {
            transform: translateX(-10px);
        }

        .slidePrice {
            transform: translateX(-10px);
        }
    }

    .buttonContainer {
        height: 22.44vw;
    }

    // Слайдер конец

    .orderButtonContainer {
        margin-top: 12px;
        padding: 0 4.44%;
    }
}