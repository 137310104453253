.filterWrap {
    background-color: rgba($color: #000000, $alpha: 0.36);
    backdrop-filter: blur(20px);

    padding: 20px 80px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    gap: 20px;

    @media (max-width: 1200px) {
        grid-template-columns: 1fr;

        padding: 20px 34px;
    }

    @media (max-width: 767px) {
        grid-template-columns: 1fr;

        padding: 20px 16px;
        background-color: rgba($color: #000000, $alpha: 0.00);

        gap: 10px;
    }
}

.yearsFilter {
    display: flex;
    align-items: center;

    justify-content: flex-start;

    @media (max-width: 1200px) {
        justify-content: space-between;
    }

    @media (max-width: 786px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.countryFilter {
    display: flex;
    align-items: center;

    justify-content: flex-end;

    @media (max-width: 1200px) {
        justify-content: space-between;
    }

    @media (max-width: 786px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.filterTitle {
    margin-right: 20px;

    font-size: 20px;
    font-weight: 300;

    color: #cccccc;

    @media (max-width: 786px) {
        font-size: 16px;
        margin-bottom: 8px;
    }
}

.yearsText {
    padding: 4px 25px;
    background-color: rgba($color: #000000, $alpha: 0.2);
    color: #a3a3a3;

    font-weight: 300;

    cursor: pointer;

    display: flex !important;
    justify-content: center !important;

    @media (max-width: 786px) {
        font-size: 14px;
    }

    span {
        color: inherit;
    }

    &.active {
        background-color: #fff;
        color: #000;

        font-weight: 500;
    }
}

.yearsWrapper {
    display: flex;
    align-items: center;

    width: 100%;

    @media (max-width: 786px) {}

    :global {
        .slick-prev {
            width: 8px;
            height: 8px;

            left: -12px;
        }

        .slick-next {
            width: 8px;
            height: 8px;

            right: -12px !important;
            top: 0;

            transform: rotate(180deg);
        }
    }
}

.arrow {
    margin: 0 10px;

    cursor: pointer;
}

.arrowNext {
    transform: rotate(180deg);
}

.search {
    display: flex;

    justify-content: flex-end;
}

.searchWrapper {
    width: 282px;
    background-color: rgba($color: #fff, $alpha: 0.4);

    padding: 4px 24px;

    @media (max-width: 1200px) {
        width: 100%;
    }

    input {
        width: 100%;

        &::placeholder {
            color: #ccc;
            font-size: 16px;
            font-weight: 300;
        }
    }
}

.searchButton {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4px 24px;
    background-color: #e41f1c;

    overflow: none;

    box-shadow: 0px 32px 70px 0px rgba(228, 31, 28, 0.7);

    cursor: pointer;

    span {
        color: #fff;
    }
}

.budgetFilter {
    display: flex;

    justify-content: flex-start;

    @media (max-width: 1200px) {
        justify-content: space-between;
    }

    @media (max-width: 786px) {
        flex-direction: column;
        align-items: flex-start;
    }

    .filterTitle {
        margin-right: 60px;
    }
}

.budgetWrapper {
    display: flex;
    align-items: center;

    span {
        margin-right: 6px;
        color: #a3a3a3;
        font-weight: 300;
    }
}

.budgetInputWrapper {
    padding: 4px 30px;
    background-color: rgba($color: #000000, $alpha: 0.2);
    color: #a3a3a3;

    font-weight: 300;

    cursor: pointer;

    max-width: 200px;

    margin-right: 20px;

    @media (max-width: 786px) {
        padding: 4px 10px;
        max-width: 130px;
    }

    &:last-child {
        margin-right: 0;
    }

    input {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        color: #fff;

        text-align: center;

        @media (max-width: 786px) {
            font-size: 14px;
        }
    }
}