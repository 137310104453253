.container {
    width: 100%;
    height: 408px;

    position: relative;

    @media (min-width: 768px) {
        height: 580px;
    }

    @media (min-width: 1100px) {
        height: 100vh;
    }
}

.bannerImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.caption {
    position: absolute;
    top: 70px;
    left: 7px;

    display: flex;

    svg {
        flex-shrink: 0;
    }

    @media (min-width: 768px) {
        top: 100px;
        left: 34px;
        flex-direction: column;

        svg {
            width: 80px;
            height: 80px;
        }
    }

    @media (min-width: 1100px) {
        top: 220px;
        left: 33px;
        flex-direction: row;
        gap: 15px;

        svg {
            width: 120px;
            height: 120px;
        }
    }
}

.marka {
    margin-top: 5px;

    color: #FFF;
    font-size: 24px;
    font-weight: 500;
    line-height: 90%;

    @media (min-width: 768px) {
        margin-top: 8px;
        font-size: 32px;
    }

    @media (min-width: 1100px) {
        margin-top: 9px;
        font-size: 40px;
    }
}

.model {
    margin-top: 2px;

    color: #FFF;
    font-size: 32px;
    font-weight: 500;
    line-height: 90%;

    @media (min-width: 768px) {
        font-size: 48px;
    }

    @media (min-width: 1100px) {
        margin-top: 8px;
        font-size: 64px;
    }
}

.slogan {
    margin-top: 20px;

    color: #3D4241;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;

    @media (min-width: 768px) {
        margin-top: 16px;
        line-height: normal;
    }

    @media (min-width: 1100px) {
        margin-top: 28px;
        font-size: 20px;
    }
}

.characteristics {
    position: absolute;
    bottom: 20px;
    right: 0;

    display: flex;
    flex-direction: column;
    gap: 8px;

    &>div {
        width: 140px;
        height: 40px;
        padding-left: 20px;
        background: rgba(255, 255, 255, 0.75);
        clip-path: polygon(20px 0%, 100% 0%, 100% 100%, 0% 100%);

        display: flex;
        align-items: center;
        gap: 4px;

        div {
            svg {
                flex-shrink: 0;
            }
        }

        &>p {
            color: #3D4241;
            font-size: 14px;
            font-weight: 500;

            span {
                color: #E41F1C;
                font-size: 24px;
                font-weight: 500;
            }
        }
    }

    @media (min-width: 768px) {
        bottom: 30px;
        right: 34px;
        gap: 16px;

        &>div {
            width: 250px;
            height: 100px;
            padding: 0 48px;
            backdrop-filter: blur(10px);
            clip-path: polygon(48px 0%, 100% 0%, calc(100% - 48px) 100%, 0% 100%);

            flex-direction: column;
            justify-content: center;
            gap: 0px;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;

                svg {
                    width: 48px;
                    height: 48px;
                }

                p {
                    color: #808080;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 16px;
                }
            }

            &>p {
                font-size: 16px;

                span {
                    font-size: 40px;
                }
            }
        }
    }

    @media (min-width: 1100px) {
        bottom: 40px;
        right: 0;
        left: 0;

        flex-direction: row;
        justify-content: center;
        gap: 0px;
    }
}