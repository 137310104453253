.container {
    width: 100%;
    padding: 25px 0;
    background: #000;
}

.sliderWrapper {
    position: relative;
    width: 100%;
    padding: 20px 16px 0 16px;
}

.slider {
    padding-bottom: 32px;

    .swiper-slide {
        height: auto;
    }
}

.slideContainer {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;

    p {
        color: #FFF;
        font-size: 20px;
        font-weight: 500;
        line-height: 120%;
    }

    video {
        width: 100%;
    }
}

.positionPrev {
    bottom: 0;
    left: calc(100% - 92px);
}

.positionNext {
    bottom: 0;
    right: 0;
}

@media (min-width: 768px) {

    .container {
        padding: 40px 0;
    }

    .sliderWrapper {
        padding: 20px 34px 0 34px;
    }

    .slider {
        padding-bottom: 52px;
    }

    .slideContainer {
        gap: 12px;

        p {
            line-height: 100%;
        }
    }

    .positionPrev {
        bottom: 0;
        left: calc(100% - 160px);
    }

}



@media (min-width: 1025px) {

    .container {
        padding: 60px 0 40px;
    }

    .sliderWrapper {
        padding: 40px 80px 0 80px;
    }

    .slider {
        padding-bottom: 60px;
    }

    .slideContainer {
        p {
            font-size: 32px;
            line-height: normal;
        }
    }

}