.container {
    width: 100%;
    padding: 10px 0 25px 0;
}

.sliderTopWrapper {
    position: relative;
    width: 100%;
    padding: 20px 16px 0 16px;

    .slideContainer {
        width: 100%;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 20px 100%, 0 90%);

        display: flex;
        flex-direction: column;

        .imgWrapper {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                margin-bottom: -5px;
            }
        }

        %reviewsBase {
            position: relative;
            width: 100%;
            background-color: #D9D9D9;
            transition: all 350ms ease;

            display: flex;
            flex-direction: column;
            gap: 4px;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;

                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(217, 217, 217, 0) 50%, rgba(217, 217, 217, 1) 77%, rgba(217, 217, 217, 1) 100%);
                transition: all 350ms ease;
            }

            span {
                color: #FFF;
                font-size: 20px;
                font-weight: 500;
                line-height: 22px;
            }

            div {
                display: grid;
                transition: all 350ms ease;

                p {
                    min-height: 0;

                    font-size: 16px;
                    font-weight: 300;
                    line-height: 22px;
                }
            }
        }

        .reviews {
            @extend %reviewsBase;
            padding: 12px 8px 40px 20px;

            &::after {
                opacity: 0;
            }

            div {
                grid-template-rows: 1fr;
            }
        }

        .reviewsHide {
            @extend %reviewsBase;
            padding: 12px 8px 140px 20px;

            &::after {
                opacity: 1;
            }

            div {
                grid-template-rows: 0fr;
            }
        }

        button {
            position: absolute;
            right: 8px;
            bottom: 10px;
            z-index: 1;

            color: #FFF;
            font-size: 14px;
            line-height: 120%;
            text-decoration-line: underline;
        }
    }
}

.sliderTop {
    padding-bottom: 32px;
}

.positionPrev {
    bottom: 0;
    left: calc(100% - 92px);
}

.positionNext {
    bottom: 0;
    right: 0;
}

.sliderBotWrapper {
    width: 100%;
    padding: 16px 16px 0;
}

.slideVideo {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
        color: #FFF;
        font-size: 20px;
        font-weight: 500;
        line-height: 100%;
    }

    video {
        width: 100%;
    }
}



@media (min-width: 768px) {

    .container {
        padding: 15px 0 50px 0;
    }

    .sliderTopWrapper {
        padding: 20px 34px 0 34px;

        .slideContainer {
            %reviewsBase {
                gap: 8px;
            }
        }
    }

    .sliderTop {
        padding-bottom: 52px;
    }

    .positionPrev {
        bottom: 0;
        left: calc(100% - 160px);
    }

    .sliderBotWrapper {
        padding: 28px 34px 0;
    }

    .slideVideo {
        gap: 12px;
    }

}



@media (min-width: 1025px) {

    .container {
        padding: 30px 0 40px 0;
    }

    .sliderTopWrapper {
        padding: 40px 80px 0 80px;

        .slideContainer {
            %reviewsBase {
                gap: 16px;

                span {
                    font-size: 32px;
                }

                div {
                    p {
                        font-size: 20px;
                    }
                }
            }

            .reviews {
                @extend %reviewsBase;
                padding: 20px 8px 40px 30px;

                &::after {
                    opacity: 0;
                }

                div {
                    grid-template-rows: 1fr;
                }
            }

            .reviewsHide {
                @extend %reviewsBase;
                padding: 20px 8px 140px 30px;

                &::after {
                    opacity: 1;
                }

                div {
                    grid-template-rows: 0fr;
                }
            }

            button {
                font-size: 16px;
            }
        }
    }

    .sliderTop {
        padding-bottom: 60px;
    }

    .sliderBotWrapper {
        padding: 40px 80px 0;
    }

    .slideVideo {
        gap: 20px;
    }

}