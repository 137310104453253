%baseButton {
    position: absolute;
    top: 0;
    z-index: 1;

    width: 30px;
    height: 100%;

    svg {
        path {
            fill: #fff;
        }
    }

    .hover {
        visibility: hidden;
        opacity: 0;
        transition: all 200ms ease;
    }

    &:disabled {
        svg {
            path {
                fill: #A3A3A3;
            }
        }
    }

    &:active {
        svg {
            path {
                fill: #000;
            }
        }
    }

    @media (min-width: 768px) {
        width: 50px;

        svg {
            width: 16px;
            height: 40px;
        }
    }

    @media (min-width: 1025px) {
        width: 60px;

        svg {
            width: 22px;
            height: 56px;
        }

        &:hover {
            .hover {
                visibility: visible;
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}

.buttonNext {
    @extend %baseButton;

    .hover {
        transform: translateX(-100%);
    }
}

.buttonPrev {
    @extend %baseButton;

    .hover {
        transform: translateX(100%);
    }
}