.catalogWrapper {
    width: 100%;
    margin-top: 80px;

    background: #000 url("../../assets/img/catalog-bg.png") center no-repeat;

    background-size: cover;

    position: relative;

    .bg_blur {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: linear-gradient(0deg,
                #000 24.75%,
                rgba(0, 0, 0, 1) 17.87%,
                rgba(0, 0, 0, 0) 48.55%);

        backdrop-filter: blur(3px);

        @media (max-width: 767px) {
            background: rgba(0, 0, 0, 0.40);
            backdrop-filter: blur(10px);
        }
    }

    @media (max-width: 767px) {
        margin-top: 48px;
        background-size: auto;
        background-position: top;
    }
}

.catalogListWrapper {
    padding: 0 80px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    column-gap: 32px;
    row-gap: 40px;

    background-color: #000;

    @media (max-width: 1200px) {
        padding: 0 34px;
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);

        padding: 0 16px;
    }
}

.paginationWrapper {
    display: flex;
    justify-content: center;
    padding: 66px 0 42px;

    background-color: #000;
}

.pagination {
    :global {
        .anticon {
            svg {
                fill: #fff !important;
            }
        }

        li.ant-pagination-item {
            // display: flex;
            // align-items: center;
            // justify-content: center;

            a {
                display: flex;
                align-items: center;
                justify-content: center;

                height: 100%;
                width: 100%;
            }
        }

        li.ant-pagination-item-active {
            background: rgba(255, 255, 255, 0.8);
            clip-path: polygon(12px 0%, 100% 0%, calc(100% - 12px) 100%, 0% 100%);
            border: none;

            border-radius: 0;
            padding: 0 20px;

            a {
                color: #000 !important;
            }
        }

        a {
            color: #fff !important;
        }
    }
}