.button {
    position: relative;

    background: #E41F1C;
    box-shadow: 0px 8px 20px -4px rgba(228, 31, 28, 0.40);
    padding: 6px 20px;

    color: #FFF;
    font-size: 16px;

    svg {
        display: none;
    }

    &:active {
        background: #000;
        color: #E41F1C;
    }

    @media (min-width: 768px) {

        padding: 12.5px 32px;
        font-size: 24px;

    }

    @media (min-width: 1025px) {

        padding: 7.5px 32px;
        font-size: 32px;
        transition: all 150ms linear;

        &::after {
            content: '';

            position: absolute;
            top: 0;
            left: 100%;

            width: 0px;
            height: 0px;
            border: 28px solid transparent;
            border-right: 0px solid transparent;
            border-left: 0px solid #E41F1C;
            box-shadow: 0px 8px 20px -4px rgba(228, 31, 28, 0.40);

            transition: all 150ms linear;
        }

        svg {
            display: block;

            position: absolute;
            top: 7.5px;
            right: 0;

            width: 32px;
            height: 41px;

            opacity: 0;
            transform: translateX(-100%);

            transition: all 150ms linear;
        }

        &:hover {
            &::after {
                border-left: 14px solid #E41F1C;
            }

            svg {
                opacity: 1;
                transform: translateX(0);
            }
        }

        &:active {
            background: #000;
            color: #E41F1C;

            &::after {
                border-left: 14px solid #000;
            }

            svg {
                path {
                    fill: #E41F1C;
                    transition: all 150ms linear;
                }
            }
        }

    }

}