.input {
    width: 100%;
    height: 67px;
    border-right: 2px solid #D9D9D9;
    border-bottom: 2px solid #D9D9D9;

    color: #fff;
    font-size: 32px;
    text-overflow: ellipsis;

    &::placeholder {
        color: #666;
    }

    &:focus {
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
    }

    &:invalid {
        border: 2px solid #E41F1C;
    }

    @media (max-width: 1024px) {

        height: 44px;
        font-size: 24px;

    }

    @media (max-width: 767px) {
        font-size: 20px;
    }
}