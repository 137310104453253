.container {
    position: absolute;
    top: 46%;
    left: 80px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    // animation: promotionAnim 400ms ease-out;

    @media (max-width: 1024px) {
        top: 40%;
        left: 4.4%;
    }

    @media (max-width: 767px) {
        top: calc(100% - 10px);
        left: 0;
        z-index: 1;

        width: 100%;
        padding: 0 31px 0 16px;
    }
}

// @keyframes promotionAnim {

//     0% {
//         transform: translateX(-100%);
//     }

//     100% {
//         transform: translateX(0);
//     }

// }

.carOnWay {
    align-self: flex-end;
    transform: translateX(20px);
    width: max-content;
    padding: 6px 32px 3px;
    background: #B0B2B3;
    clip-path: polygon(20px 0%, 100% 0%, calc(100% - 20px) 100%, 0% 100%);

    color: #3D4241;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;

    @media (max-width: 1250px) {
        font-size: 20px;
    }

    @media (max-width: 767px) {
        transform: translateX(15px);
        padding: 3px 20px 3px;
        clip-path: polygon(15px 0%, 100% 0%, calc(100% - 15px) 100%, 0% 100%);
        font-size: 16px;
    }
}

.timerContainer {
    padding: 20px 80px 30px;
    background: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(40px);
    clip-path: polygon(80px 0%, 100% 0%, calc(100% - 80px) 100%, 0% 100%);

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1250px) {
        padding: 10px 30px 20px 25px;
        clip-path: polygon(70px 0%, 100% 0%, calc(100% - 70px) 100%, 0% 100%);
    }

    @media (max-width: 767px) {
        justify-content: center;
        height: 32vw;
        padding: 0px 20px 15px 10px;
        clip-path: polygon(16.1vw 0%, 100% 0%, calc(100% - 16.1vw) 100%, 0% 100%);
    }
}

.timerCaption {
    display: flex;
    align-items: center;

    svg {
        flex-shrink: 0;
    }

    p {
        color: #D9D9D9;
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
    }

    @media (max-width: 1250px) {
        transform: translateX(30px);

        p {
            max-width: 230px;
            font-size: 20px;
        }
    }

    @media (max-width: 767px) {
        transform: translateX(0px);

        p {
            max-width: 150px;
            font-size: 16px;
        }
    }
}

.timer {
    margin-top: -10px;
    animation: timerAnim 900ms ease;

    p {
        color: #FDCBCB;
        text-shadow:
            0 0 1px #FDCBCB,
            0 0 2px #FDCBCB,
            0 0 1px #E41F1C,
            0 0 2px #E41F1C,
            0 0 3px #E41F1C,
            0 0 6px #E41F1C,
            0 0 10px #E41F1C;
        ;
        -webkit-text-stroke: 2px #E41F1C;
        font-weight: 900;
        font-size: 45px;
        letter-spacing: 5px;
    }

    @media (max-width: 1250px) {
        p {
            font-size: 30px;
        }
    }

    @media (max-width: 767px) {
        p {
            font-size: 25px;
        }
    }
}

@keyframes timerAnim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.specPrice {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
        color: #FFF;
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
    }

    @media (max-width: 1250px) {
        p {
            max-width: 115px;
            font-size: 20px;
        }
    }

    @media (max-width: 767px) {
        &>div {
            clip-path: polygon(20px 0%, 100% 0%, calc(100% - 20px) 100%, 0% 100%);
        }

        p {
            max-width: 100px;
            font-size: 16px;
        }
    }
}