.input {
    width: 100%;
    height: 48px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;

    color: #fff;
    font-size: 24px;
    text-overflow: ellipsis;

    &::placeholder {
        color: #a7a7a7;
    }

    &:invalid {
        border: 2px solid #E41F1C;
    }

    @media (max-width: 1024px) {
        font-size: 16px;
    }

    @media (max-width: 767px) {
        height: 40px;
    }
}