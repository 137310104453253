.main {
    width: 100%;

    h2 {
        color: #BDBDBD;
        font-size: 32px;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.videoWrapper {
    position: relative;
    z-index: 2;
    width: 100%;
    padding-top: 80px;
    background-color: #000;
    border-bottom: 1px solid #e41c1c;

    &::after {
        content: '';

        position: absolute;
        top: 100%;
        left: 0;

        width: 100%;
        height: 10%;
        background: linear-gradient(180deg, #e41f1c59 0%, #e41f1c00 100%);
    }

    video {
        width: 100%;
        height: 100%;
        margin-bottom: -5px;
    }

    @media (max-width: 767px) {
        padding-top: 0;
        border-top: 1px solid #e41c1c;
    }
}

.plashka {
    position: absolute;
    top: 47%;
    right: 0;

    padding: 1.9vw 0 1.9vw 1.9vw;
    background: rgba(0, 0, 0, 0.8);
    transform: translateY(-50%);

    display: flex;
    flex-direction: column;
    gap: 15px;

    p {
        padding-right: 3.1vw;

        color: #FFF;
        font-size: 1.7vw;
        text-transform: lowercase;
        white-space: pre-wrap;

        @media (max-width: 767px) {
            padding-right: 12px;
            font-size: 12px;
        }
    }

    @media (max-width: 1024px) {
        gap: 10px;
    }

    @media (max-width: 767px) {
        top: 50%;

        padding: 12px 0 12px 12px;

        gap: 4px;
    }
}

.logoOnVideo {
    width: 8.6vw;
    height: 3.3vw;
    flex-shrink: 0;

    @media (max-width: 767px) {
        width: 57px;
        height: 22px;
    }
}

.decor {
    width: 100%;
    height: 4px;
    background: #E41F1C;

    @media (max-width: 767px) {
        height: 2px;
    }
}

.btn {
    width: 8.7vw;
    height: 2.2vw;
    background: #E41F1C;
    box-shadow: 0px 8px 20px -4px rgba(228, 31, 28, 0.40);
    transition: all 200ms ease;

    color: #FFF;
    font-size: 1.2vw;
    text-transform: uppercase;

    &:hover {
        background: #000;
        color: #E41F1C;
    }

    @media (max-width: 767px) {
        width: 80px;
        height: 20px;
        font-size: 11px;
    }
}

.timeContainer {
    position: absolute;
    top: 105px;
    left: 0;

    width: 100%;

    display: flex;
    justify-content: center;
    gap: 70px;

    @media (max-width: 1460px) {
        gap: 25px;
    }

    @media (max-width: 1300px) {
        padding-left: 40px;
        justify-content: flex-start;
    }

    @media (max-width: 1025px) {
        gap: 10px;
    }

    @media (max-width: 767px) {
        position: static;
        padding: 60px 16px 12px;
        background: rgba(0, 0, 0, 0.85);

        justify-content: center;
    }

    @media (max-width: 530px) {
        flex-direction: column;
        gap: 8px;
    }
}

.timeBlock {
    padding: 0px 15px;
    background: rgba(0, 0, 0, 0.8);

    display: flex;
    align-items: center;
    gap: 15px;

    p {
        color: #FFF;
        font-size: 20px;
        font-weight: 300;
        line-height: 140%;
        text-transform: uppercase;

        @media (max-width: 1025px) {
            font-size: 16px;
        }

        @media (max-width: 600px) {
            font-size: 14px;
        }

        @media (max-width: 360px) {
            font-size: 12px;
        }
    }

    div {
        width: 1px;
        height: 100%;
        background: #FFF;
    }

    @media (max-width: 1025px) {
        padding: 0px 10px;
        gap: 8px;
    }

    @media (max-width: 600px) {
        justify-content: space-between;
    }
}

.mainBackgroundFon {
    width: 100%;
    margin-top: -100px;
    background: url(../../assets/img/main_fon.png);
    background-size: cover;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 150px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 1.00) 100%);
    }

    @media (max-width: 1024px) {
        background: url(../../assets/img/main_fon_tablet.png);
        background-size: cover;
    }

    @media (max-width: 767px) {
        margin-top: -70px;
        background: url(../../assets/img/main_fon_mobile.png);
        background-size: cover;
    }
}


@media (max-width: 767px) {

    .main {
        h2 {
            font-size: 20px;
        }
    }

}